import { global } from ":global";
import { ENDPOINTS } from "./endpoints";

export async function get_user() {
  return global.api
    .getAuth(ENDPOINTS.get_user)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}
